import moment from 'moment'

export default {
    data() {
        return {
            month: null,
            dateStart: null,
            dateEnd: null,
            dateField: 'created_at',
        }
    },
    methods: {
        monthChanged(val) {
            if (val == null) {
                this.dateStart = null
                this.dateEnd = null
                this.month = null

                this.$delete(this.filter, this.dateField)

                return
            }

            this.dateStart = moment('01.' + val, 'DD.MM.YYYY').startOf('M').format('DD.MM.YYYY')
            this.dateEnd = moment('01.' + val, 'DD.MM.YYYY').endOf('M').format('DD.MM.YYYY')

            this.$set(this.filter, this.dateField, this.dateStart + ';' + this.dateEnd)
            this.$set(this.filter, 'filterChanged', true)
        },
        prevMonth() {
            let prevMonth = moment('01.' + this.month, 'DD.MM.YYYY').subtract(1, 'month').format('MM.YYYY')

            this.month = prevMonth;
            this.monthChanged(prevMonth)
        },
        nextMonth() {
            let nextMonth = moment('01.' + this.month, 'DD.MM.YYYY').add(1, 'month').format('MM.YYYY')

            this.month = nextMonth;
            this.monthChanged(nextMonth)
        },
        resetDate() {
            this.dateStart = moment().startOf('M').format('DD.MM.YYYY')
            this.dateEnd = moment().endOf('M').format('DD.MM.YYYY')
            this.month = moment().format('DD.MM')
        },
        monthsList() {
            let list = []
            list.push({value: null, text: '-'})

            for (let i = 0; i <= 17; i++) {
                list.push({
                    value: moment().subtract(i, 'months').startOf('M').format('MM.YYYY'),
                    text: moment().subtract(i, 'months').format('YYYY MMMM')
                })
            }

            return list
        },
    },
}
